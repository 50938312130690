import get from '../api/get';

/**
 * @typedef {Object} CustomersQueryOptions
 * @property {import('../../types').CustomerStatus} status
 */

/**
 *
 * @param {CustomersQueryOptions} [options]
 * @returns {import('../../types').Customer[]} customers
 */
export default function getCustomers({ status } = {}) {
    if (status) {
        return get(`/api/v1/customers?status=${status}`);
    }
    return get('/api/v1/customers');
}
