import { useNavigate, Outlet } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import processUnhandledError from './services/processUnhandledError';

/**
 * This component is responsible for catching unhandled exceptions thrown by asynchronous functions. Specifically, this
 * is used to catch and handle expected API errors such as 401, which we can then use to redirect users to /login.
 */
export default function UnhandledExceptionErrorBoundary() {
    const navigate = useNavigate();

    const promiseRejectionHandler = useCallback(
        (event) => processUnhandledError(event.reason, navigate),
        [navigate],
    );

    useEffect(() => {
        window.addEventListener('unhandledrejection', promiseRejectionHandler);

        return () => {
            window.removeEventListener('unhandledrejection', promiseRejectionHandler);
        };
    }, [promiseRejectionHandler]);

    return <Outlet />;
}
