const PageGroupType = Object.freeze({
    FAMILY: 'family',
    CUSTOMER: 'customer',
    PLATFORM: 'platform',
});
export default PageGroupType;

export const PageGroupUri = Object.freeze({
    PLATFORM: 'platforms',
    FAMILY: 'families',
    CUSTOMER: 'customers',
});

export function getPageGroupTypeFromUri(uri) {
    return (
        Object.keys(PageGroupUri).find((key) => PageGroupUri[key] === uri) &&
        PageGroupType[Object.keys(PageGroupUri).find((key) => PageGroupUri[key] === uri)]
    );
}
