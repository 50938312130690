import postProcessApiResponse from '../postProcessApiResponse';
import { showInternalErrorNotification, showUnexpectedErrorNotification } from '../notifications';
import ApiError, { ApiErrorType } from './ApiError';
import handleUnauthorizedRequest from './handleUnauthorizedRequest';

export default async function get(url, { signal } = {}) {
    const apiResponse = await fetch(url, { signal });

    if (apiResponse.status === 401) {
        handleUnauthorizedRequest();
        return null;
    }

    if (signal && signal.aborted) {
        return null;
    }

    if (apiResponse.status === 500) {
        showInternalErrorNotification();
    } else if (apiResponse.status !== 200) {
        showUnexpectedErrorNotification();
    }

    if (apiResponse.status !== 200) {
        throw new ApiError(ApiErrorType.BAD_STATUS);
    }

    return postProcessApiResponse(await apiResponse.json());
}
