import ApiError, { ApiErrorType } from './api/ApiError';

export default function processUnhandledError(error, navigate) {
    if (error instanceof ApiError) {
        if (error.type === ApiErrorType.UNAUTHORIZED) {
            navigate('/login?session-expired=true');
        }
    } else {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}
