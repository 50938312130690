import { css } from '@emotion/core';

export const overrideDefaultTableCSS = css`
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
    }
    table {
        border-spacing: 0;
    }
`;
