import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LogsPageContext from './LogsPageContext';

export default function LogsPageProvider({ children }) {
    const [page, setPage] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);
    const MAX_SEARCH_RESULTS_PER_TYPE = 3;
    const logsContext = useMemo(
        () => ({
            page,
            setPage,
            selectedItem,
            setSelectedItem,
            searchText,
            setSearchText,
            MAX_SEARCH_RESULTS_PER_TYPE,
            showSearchResults,
            setShowSearchResults,
        }),
        [page, selectedItem, searchText, showSearchResults],
    );
    return <LogsPageContext.Provider value={logsContext}>{children}</LogsPageContext.Provider>;
}
LogsPageProvider.propTypes = {
    children: PropTypes.node,
};
