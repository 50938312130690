import { useReducer, useMemo } from 'react';

const reducerActions = {
    FETCH_COMPLETE: 'FETCH_COMPLETE',
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    RESET: 'RESET',
};

function reducer(state, action) {
    if (action.type === reducerActions.FETCH_COMPLETE) {
        return {
            ...state,
            error: false,
            loading: false,
            complete: true,
            results: action.results,
        };
    }
    if (action.type === reducerActions.ERROR) {
        return {
            ...state,
            error: action.error,
            complete: true,
            loading: false,
        };
    }
    if (action.type === reducerActions.LOADING && !state.loading) {
        return {
            ...state,
            loading: true,
            complete: false,
        };
    }
    if (action.type === reducerActions.RESET) {
        return {
            ...state,
            loading: false,
            error: false,
            results: null,
            complete: false,
        };
    }
    return state;
}

/**
 * Utility hook for other hooks fetching data, providing basic state and actions for the state.
 *
 * @typedef {any} results Object containing the results of the api. This fetched result could be null.
 * @typedef {boolean} loading If the api is currently being fetched.
 * @typedef {boolean} complete true if the result has been fetched
 * @typedef {string} error Error message, if the fetch was unsuccessful.
 *
 * @typedef {Function} fetchComplete Function to call when fetching for results is completed.
 * @typedef {Function} error Function to call when an error occurred.
 * @typedef {Function} loading Function to call when the hook is currently fetching for results.
 *
 * @param {Object} [defaultState] Allows any initial override to state (results, error, loading).
 *
 * @typedef {{results, error, loading, complete}, {fetchComplete, error, loading}}
 *
 * @example
 *
 * const useApiFetcher = () => {
 *    const [state, actions] = useApiReducer();
 *    const { error, loading, results } = state;
 *
 *    const fetchData = () => {
 *        let isCancelled = false;
 *        actions.loading();
 *        getSomeData()
 *            .then((data) => {
 *                if (!isCancelled) {
 *                    actions.fetchComplete(data.payload);
 *                }
 *            }).catch((e) => {
 *            if (!isCancelled) {
 *                actions.error(e);
 *            }
 *        });
 *        return () => {
 *            isCancelled = true;
 *        };
 *    };
 *
 *    useEffect(() => fetchData(), []);
 *    return [results, loading, error];
 * };
 *
 *
 * @example
 *
 * const useAsyncRequest = (requestFn) {
 *   const [state, actions] = useApiReducer({
 *       loading: false,
 *       complete: false,
 *   });
 *
 *   useEffect(() => {
 *       if (state.loading || state.complete || state.error) {
 *           return;
 *       }
 *
 *       const resolve = (payload) => {
 *          actions.fetchComplete(payload);
 *      };
 *      const reject = (payload) => {
 *          actions.error(payload);
 *      };
 *
 *      actions.loading();
 *
 *      requestFn?.().then(resolve, reject);
 *  }, [state, actions, requestFn]);
 *  return [state, actions];
 *
 */
function useApiReducer(defaultState) {
    const [state, dispatch] = useReducer(reducer, {
        results: null,
        error: null,
        loading: false,
        complete: false,
        ...defaultState,
    });

    // useMemo to allow this to be a dependency without causing unnecessary re-renders
    const actions = useMemo(
        () => ({
            fetchComplete: (results) => dispatch({ type: reducerActions.FETCH_COMPLETE, results }),
            error: (error) => dispatch({ type: reducerActions.ERROR, error }),
            loading: () => dispatch({ type: reducerActions.LOADING }),
            reset: () => dispatch({ type: reducerActions.RESET }),
        }),
        [],
    );

    return [state, actions];
}

export default useApiReducer;
