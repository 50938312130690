import { Outlet } from 'react-router-dom';
import InformationIsConfidentialFooter from './components/InformationIsConfidentialFooter';
import { informationIsConfidentialFooterCSS } from './components/styles/InformationIsConfidentialFooter.styles';

export default function ConfidentialFooterContainer() {
    return (
        <>
            <Outlet />
            <InformationIsConfidentialFooter css={informationIsConfidentialFooterCSS} />
        </>
    );
}
