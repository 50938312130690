import ApiError, { ApiErrorType } from './api/ApiError';
import { showUnexpectedErrorNotification } from './notifications';

export default function handleApiError(url, error) {
    if (error instanceof ApiError) {
        if (error.type === ApiErrorType.UNAUTHORIZED) {
            throw error;
        }
        // Ignore other API errors as they are handled in services/api/* functions (e.g., services/api/get.js)
    } else if (error.name !== 'AbortError') {
        // Ignore "AbortError" as this happens if we cancel an in-flight request, which can be safely ignored.
        showUnexpectedErrorNotification();
        // eslint-disable-next-line no-console
        console.error('Unhandled error when processing request', url, error);
    }
}
