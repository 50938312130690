/**
 * @typedef {Object} PermissionSet
 * @property {string[]} permissions - Permissions themselves (UpdateCustomer, UpdateClient, etc)
 * @property {string[]} families - Permission keys that are assigned to the given permissions. This can be any of the following:
 *      - * (permission applies to all families)
 *      - platform_key:* (permission apples to all families where platform_family_key = platform_key)
 *      - family_key (permission only applies to given family)
 */

/**
 * @typedef {Object} User
 * @property {string} email
 * @property {string} givenName
 * @property {string} profileImageUrl
 * @property {PermissionSet[]} permission_sets
 */

/**
 * @typedef {Object} Application
 * @property {string} key - Unique identifier
 * @property {string} label
 * @property {number} order
 */

/**
 * @typedef {Object} PermissionForFamily
 * @property {boolean} all
 * @property {string[]} platforms
 * @property {string[]} families
 */

/**
 * @typedef {Object.<string, PermissionForFamily>} PermissionsToFamilies Permission keys to PermissionForFamily
 */

/** @enum {string} */
export const StatMetadataStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

/**
 * @typedef {Object} StatMetadata
 * @property {string} label
 * @property {string} description
 * @property {string} source - Where the data originated from (e.g., VMC)
 * @property {string} type - How the data is presented (e.g., average, total)
 * @property {StatMetadataStatus} status
 * @property {number} section_order
 */

/**
 * @typedef {Object} FlattenedStatMetadata
 * @extends StatMetadata
 * @property {string} key - Unique Identifier
 */

/** @enum {string} */
export const FamilyStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

/**
 * @typedef {Object} Family
 * @property {number} id - Database Identifier
 * @property {string} key - Unique identifier
 * @property {string} label
 * @property {string} [platform_family_key] - The parent of the given family, if anything
 * @property {Application[]} [applications]
 * @property {Object.<string, StatMetadata>} stat_metadata
 */

/** @enum {string} */
export const CustomerStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    MERGED: 'MERGED',
    INACTIVE: 'INACTIVE',
};

/**
 * @typedef {Object} Customer
 * @property {number} id - Database Identifier
 * @property {string} label
 * @property {string} created_by
 * @property {date} created_date
 */

// Export nothing to mark this file as a valid module
export {};
