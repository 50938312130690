/**
 * Checks if the given permission is allowed for the user and given family, if any.
 *
 * @param {import('../../types').PermissionsToFamilies} permissionsToFamilies
 * @param {import('./Permissions').Permission} permission
 * @param {import('../../types').Family} [family]
 *
 * @returns {boolean} true if user has permission
 */
export default function hasPermission(permissionsToFamilies, permission, family) {
    if (!permissionsToFamilies[permission]) {
        return false;
    }

    if (permissionsToFamilies[permission].all) {
        return true;
    }

    return (
        family &&
        (permissionsToFamilies[permission].platforms.includes(family.platform_family_key) ||
            permissionsToFamilies[permission].families.includes(family.key))
    );
}
