import PropTypes from 'prop-types';
import { theme as corgixTheme } from '@veeva/corgix/theme';
import { ThemeProvider } from 'emotion-theming';
import { css, Global } from '@emotion/core';
import colors from './theme/colors';
import fontSizes from './theme/fontSizes';
import sidebar from './theme/sidebar';
import { overrideDefaultTableCSS } from './theme/globalOverrides.styles';

const theme = {
    ...corgixTheme,
    fontFamily: 'Inter, Arial, Helvetica, sans-serif',
    menuSpacing: '0.75rem',
    menuSpacingVariant1: '1rem',

    textInputBorderRadius: '0.5rem',
    textInputTextColorPlaceholder: colors.textSecondary,
    buttonSpacing: '0.5rem',
    buttonBorderRadius: '0.5rem',

    buttonPrimaryBackgroundColorDefault: colors.primary,
    buttonPrimaryBackgroundColorHover: colors.primaryBackgroundColorActive,
    buttonPrimaryBackgroundColorActive: colors.primaryBackgroundColorActive,
    buttonPrimaryBackgroundColorFocus: colors.primaryBackgroundColorFocus,
    inputBorderColorDefault: colors.neutral50,
    inputBorderColorHover: colors.neutral30,
    inputBorderColorFocus: colors.blue60,

    buttonPrimaryBorderColorDefault: colors.primary,
    buttonPrimaryBorderColorHover: colors.primaryBackgroundColorActive,
    buttonPrimaryBorderColorFocus: colors.primaryBackgroundColorActive,
    buttonPrimaryBorderColorActive: colors.primaryBackgroundColorFocus,

    menuFontSize: fontSizes.small,
    menuFontSizeHeader: fontSizes.small,
    menuTextColorHeader: colors.black,
    menuTextColorDefault: colors.black,

    selectWidthMD: '16.25rem',
    selectBorderRadius: '0.5rem',

    menuBorderRadius: '0.5rem',
    dropdownMenuBorderRadius: '0.25rem',
    menuItemBackgroundColorHover: colors.neutral99,
    menuDividerLineColorDefault: colors.neutral80,

    dropdownMenuPrimaryBackgroundColorDefault: colors.primary,
    dropdownMenuPrimaryBackgroundColorHover: colors.primaryBackgroundColorActive,
    dropdownMenuPrimaryBackgroundColorActive: colors.primaryBackgroundColorActive,
    dropdownMenuPrimaryBackgroundColorFocus: colors.primaryBackgroundColorFocus,

    dropdownMenuPrimaryBorderColorDefault: colors.primary,
    dropdownMenuPrimaryBorderColorHover: colors.primaryBackgroundColorActive,
    dropdownMenuPrimaryBorderColorFocus: colors.primaryBackgroundColorActive,
    dropdownMenuPrimaryBorderColorActive: colors.primaryBackgroundColorFocus,

    formFieldLabelLineHeight: '1.5rem',

    tooltipMaxWidth: '18.75rem',
    colors,
    fontSizes,
    sidebar,
};

function AppStatsThemeProvider({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <Global
                styles={({ fontFamily }) => {
                    return css`
                        html,
                        body {
                            font-family: ${fontFamily};
                            color: ${colors.black};
                            line-height: 1.5rem;
                            padding: 0;
                            margin: 0;
                        }

                        body {
                            height: 100%;
                            margin: 0;
                        }
                        html {
                            // this is to add a bottom padding above the confidential footer so that it doesn't go inside the stats body on pages with scrollable contents
                            padding-bottom: 50px;
                            min-height: 100%;
                            box-sizing: border-box;
                            position: relative;
                        }

                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        h6 {
                            all: unset;
                        }

                        a {
                            color: ${colors.blue60};
                            text-decoration: none;
                        }
                        b {
                            color: ${colors.black};
                        }

                        ${overrideDefaultTableCSS};
                    `;
                }}
            />
            {children}
        </ThemeProvider>
    );
}

AppStatsThemeProvider.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

export default AppStatsThemeProvider;
