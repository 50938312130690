import { useEffect } from 'react';
import useApiReducer from './useApiReducer';
import get from './api/get';
import handleApiError from './handleApiError';

export default function useGetRequest(url, { enabled = true } = {}) {
    const [state, actions] = useApiReducer({
        loading: true,
    });

    const fetchData = () => {
        if (!enabled) {
            return undefined;
        }

        const abortController = new AbortController();
        actions.loading();
        get(url, { signal: abortController.signal })
            .then((results) => {
                if (!abortController.signal.aborted) {
                    actions.fetchComplete(results);
                }
            })
            .catch((e) => {
                handleApiError(url, e);
                actions.error(true);
            });

        return () => abortController.abort();
    };
    // Only fetch on initial render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchData(), [actions, url, enabled]);

    return [state.results, state.loading, state.error, fetchData];
}
