export const ApiErrorType = {
    UNAUTHORIZED: 'unauthorized',
    BAD_STATUS: 'bad_status',
    SERVER_ERROR: 'server_error',
};

export default class ApiError extends Error {
    constructor(apiErrorType, response) {
        super('ApiError', response);
        this.type = apiErrorType;
        this.apiResponse = response;
    }
}
