import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { navigationBarCSS, navigationBarContentCSS } from './styles/NavigationBar.styles';
import ResponsiveContainer from '../ResponsiveContainer';
import OverlayContext from '../../OverlayContext';
import appstatsTitle from '../../images/appstats-title.svg';

function NavigationBarWithRef({ children, className }, ref) {
    const { setOverlay } = useContext(OverlayContext);
    const location = useLocation();
    const handleAppStatsClick = () => setOverlay?.(null);

    return (
        <header className={className} css={navigationBarCSS} ref={ref}>
            <ResponsiveContainer css={navigationBarContentCSS}>
                <h1>
                    {location.pathname === '/login' ? (
                        <img alt="AppStats" src={appstatsTitle} />
                    ) : (
                        <Link to="/" onClick={handleAppStatsClick}>
                            <img alt="AppStats" src={appstatsTitle} />
                        </Link>
                    )}
                </h1>
                {children}
            </ResponsiveContainer>
        </header>
    );
}

const NavigationBar = React.forwardRef(NavigationBarWithRef);

NavigationBarWithRef.propTypes = {
    children: PropTypes.node,
    className: PropTypes.node,
};

export default NavigationBar;
