/**
 * Maps user.permission_sets in a way where we can check a given permission/family is matched.
 *
 * @param {import('../../types').User} user
 *
 * @returns {import('../../types').PermissionsToFamilies} A map of permission keys to what is allowed for a family
 */
export default function getPermissionsToFamilies(user) {
    if (!user) {
        return {};
    }
    return user.permission_sets.reduce((acc, { families: familyPermissionKeys, permissions }) => {
        permissions.forEach((p) => {
            if (!(p in acc)) {
                acc[p] = {
                    all: false,
                    platforms: [],
                    families: [],
                };
            }

            familyPermissionKeys.forEach((familyPermissionKey) => {
                if (familyPermissionKey === '*') {
                    acc[p].all = true;
                } else if (familyPermissionKey.includes(':')) {
                    const [platformFamilyKey] = familyPermissionKey.split(':');
                    if (!acc[p].platforms.includes(platformFamilyKey)) {
                        acc[p].platforms.push(platformFamilyKey);
                    }
                } else if (!acc[p].families.includes(familyPermissionKey)) {
                    acc[p].families.push(familyPermissionKey);
                }
            });
        });

        return acc;
    }, {});
}
