import get from '../api/get';

/**
 * @typedef {Object} FamiliesQueryOptions
 * @property {import('../../types').FamilyStatus} status
 */

/**
 * Returns a list of all families
 *
 * @param {FamiliesQueryOptions} options
 * @returns {import('../../types').Family[]} families
 */
export default function getFamilies({ status } = {}) {
    if (status) {
        return get(`/api/v1/families?status=${status}`);
    }
    return get('/api/v1/families');
}
