import PropTypes from 'prop-types';
import { pageWithContentContainerCSS } from './styles/PageWithContent.styles';
import ResponsiveContainer from './ResponsiveContainer';

export default function PageWithContent({ children, className }) {
    return (
        <ResponsiveContainer className={className} css={pageWithContentContainerCSS}>
            {children}
        </ResponsiveContainer>
    );
}
PageWithContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
