import PropTypes from 'prop-types';

export default function UnexpectedServerError({ uuid }) {
    return (
        <div>
            <h2>Internal Error</h2>
            <p>Please contact support for help and include the UUID provided below:</p>
            <p>
                <b>UUID: </b> {uuid}
            </p>
        </div>
    );
}

UnexpectedServerError.propTypes = {
    uuid: PropTypes.string.isRequired,
};
