import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NavigationBar from '../components/Navigation/NavigationBar';
import UnexpectedServerError from '../components/errors/UnexpectedServerError';
import UnexpectedClientError from '../components/errors/UnexpectedClientError';
import PageWithContent from '../components/PageWithContent';
import { errorPageContainerOverrideCSS } from './styles/ErrorPage.styles';
import processUnhandledError from '../services/processUnhandledError';

// eslint-disable-next-line react/display-name
export default function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();
    const [showPageContent, setShowPageContent] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (error) {
            processUnhandledError(error, navigate);
        }
        setShowPageContent(true);
    }, [error, navigate]);

    // Use this state to allow processUnhandledError to complete, which may redirect elsewhere (causing a page flash)
    if (!showPageContent) {
        return null;
    }

    const uuid = searchParams.get('uuid');
    return (
        <>
            <NavigationBar />
            <PageWithContent css={errorPageContainerOverrideCSS}>
                {uuid ? <UnexpectedServerError uuid={uuid} /> : <UnexpectedClientError />}
            </PageWithContent>
        </>
    );
}
