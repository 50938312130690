import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppContext from './AppContext';
import Loading from './components/Loading';
import getPodNames from './services/data/getPodNames';
import useGetRequest from './services/useGetRequest';
import getCustomers from './services/data/getCustomers';
import { CustomerStatus, FamilyStatus } from './types';
import getFamilies from './services/data/getFamilies';
import getPermissionsToFamilies from './services/permissions/getPermissionsToFamilies';
import hasPermission from './services/permissions/hasPermission';
import getRecentlySearchedItems from './services/data/getRecentlySearchedItems';
import PageGroupType from './components/PageGroupType';

export default function AuthenticatedContainer() {
    const [user, userIsLoading] = useGetRequest('/ui/session/userinfo');
    const [customers, setCustomers] = useState(null);
    const [families, setFamilies] = useState(null);
    const [recentlySearchedIdsByType, setRecentlySearchedIdsByType] = useState(null);
    const [dateRangeSelectedOption, setDateRangeSelectedOption] = useState('Daily');
    const [podNames, setPodNames] = useState(null);

    useEffect(() => {
        (async () => {
            const [
                initialCustomers,
                initialFamilies,
                initialRecentlySearchedFamilyIds,
                initialRecentlySearchedCustomerIds,
                initialRecentlySearchedPlatformIds,
                initialPods,
            ] = await Promise.all([
                getCustomers(),
                getFamilies({ status: FamilyStatus.ACTIVE }),
                getRecentlySearchedItems(PageGroupType.FAMILY),
                getRecentlySearchedItems(PageGroupType.CUSTOMER),
                getRecentlySearchedItems(PageGroupType.PLATFORM),
                getPodNames(),
            ]);
            setCustomers(initialCustomers.filter(({ status }) => status !== CustomerStatus.MERGED));
            setFamilies(initialFamilies);
            setRecentlySearchedIdsByType({
                platform: initialRecentlySearchedPlatformIds,
                family: initialRecentlySearchedFamilyIds,
                customer: initialRecentlySearchedCustomerIds,
            });
            setPodNames(initialPods);
        })();
    }, []);

    const appContext = useMemo(() => {
        const permissionsToFamilies = getPermissionsToFamilies(user);
        return {
            user,
            customers,
            setCustomers,
            families,
            setFamilies,
            recentlySearchedIdsByType,
            setRecentlySearchedIdsByType,
            dateRangeSelectedOption,
            setDateRangeSelectedOption,
            permissionsToFamilies,
            userHasPermission: (permission, family) =>
                hasPermission(permissionsToFamilies, permission, family),
            podNames,
            setPodNames,
        };
    }, [user, customers, families, recentlySearchedIdsByType, dateRangeSelectedOption, podNames]);

    if (userIsLoading || !customers || !families) {
        return <Loading />;
    }

    // Outlet will serve any content that a nested <Route /> matches.
    return (
        <AppContext.Provider value={appContext}>
            <Outlet />
        </AppContext.Provider>
    );
}
