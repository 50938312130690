import { css } from '@emotion/core';
import { MEDIA_BREAKPOINTS } from '../../../services/breakpoints';

export const navigationBarContentCSS = css`
    height: 100%;

    ${MEDIA_BREAKPOINTS.SMALL_MIN} {
        gap: 5rem;
    }

    ${MEDIA_BREAKPOINTS.EXTRA_SMALL} {
        margin: 0 1rem;
        align-items: center; // Shrinks the search box otherwise
    }
`;

export const navigationBarCSS = ({ colors, fontSizes }) => css`
    display: block;
    width: 100%;
    background-color: ${colors.primary};
    height: 4rem;
    flex: none;

    h1 {
        align-self: center;

        > button {
            :hover {
                color: ${colors.white};
            }

            all: unset;
            cursor: pointer;
            color: ${colors.white};
            font-style: normal;
            font-weight: 400;
            text-decoration: none;
            line-height: 2.5rem;
            display: flex;

            ${MEDIA_BREAKPOINTS.EXTRA_SMALL} {
                font-size: ${fontSizes.large};
            }

            ${MEDIA_BREAKPOINTS.SMALL_MIN} {
                font-size: ${fontSizes.extraLarge};
            }
        }
    }
`;
