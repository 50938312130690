import { css } from '@emotion/core';
import { MEDIA_BREAKPOINTS } from '../../services/breakpoints';

export const pageWithContentContainerCSS = css`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-top: 0.75rem;

    ${MEDIA_BREAKPOINTS.EXTRA_SMALL} {
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;
