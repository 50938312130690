import PropTypes from 'prop-types';
import { responsiveContainerCSS } from './styles/ResponsiveContainer.styles';

export default function ResponsiveContainer({ className, children }) {
    return (
        <div className={className} css={responsiveContainerCSS}>
            {children}
        </div>
    );
}
ResponsiveContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
