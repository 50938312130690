import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import OverlayContext from './OverlayContext';

export default function MobileFriendlyContainer() {
    const [overlay, setOverlay] = useState(null);

    const overlayContext = useMemo(() => {
        const handleSetOverlay = (name, metadata) => {
            setOverlay({ name, metadata });
        };

        return {
            overlay: overlay?.name,
            metadata: overlay?.metadata,
            setOverlay: handleSetOverlay,
        };
    }, [overlay, setOverlay]);

    // Outlet will serve any content that a nested <Route /> matches.
    return (
        <OverlayContext.Provider value={overlayContext}>
            <Outlet />
        </OverlayContext.Provider>
    );
}
