import ResponsiveContainer from './ResponsiveContainer';
import NavigationBar from './Navigation/NavigationBar';

export default function Loading() {
    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>Loading...</ResponsiveContainer>
        </>
    );
}
