import { css } from '@emotion/core';

export const pageNotFoundPageContainerCSS = ({ colors }) => css`
    padding: 0.75rem 0;
    align-self: flex-start;

    h2 {
        font-size: 1.25rem;
        margin: 1rem 0 0 0;
        font-weight: bold;
    }

    a {
        color: ${colors.blue60};
        text-decoration: none;
    }
`;
