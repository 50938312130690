import 'core-js';
import { createRoot } from 'react-dom/client';
import App from './App';
import AppStatsThemeProvider from './AppStatsThemeProvider';

const initReactApp = () => {
    const container = document.getElementById('appstats');
    const root = createRoot(container);

    root.render(
        <AppStatsThemeProvider>
            <App />
        </AppStatsThemeProvider>,
    );
};

// USE_MOCK_APIS is defined by Webpack based on the environment flag "USE_MOCK_APIS".
// eslint-disable-next-line no-undef
if (USE_MOCK_APIS) {
    import(/* webpackChunkName: "browserMock" */ './mocks/browser').then(({ worker }) => {
        (async () => {
            await worker.start({
                // Ignore logs when we hit APIs. We generally don't need this very often (Network tab is sufficient)
                quiet: true,
                // Ignored 'unhandled' requests as some come from external sources (images, webpack HMR) and it's generally obvious when data is missing...
                onUnhandledRequest: 'bypass',
            });
            initReactApp();
        })();
    });
} else {
    initReactApp();
}
