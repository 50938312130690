import NavigationBar from '../components/Navigation/NavigationBar';
import { pageNotFoundPageContainerCSS } from './styles/PageNotFoundPage.styles';
import PageWithContent from '../components/PageWithContent';

export default function Component() {
    return (
        <>
            <NavigationBar />
            <PageWithContent>
                <div css={pageNotFoundPageContainerCSS}>
                    <h2>Page not found</h2>
                    <p>You may have reached this page for one of the following reasons:</p>
                    <ul>
                        <li>The URL was incorrect</li>
                        <li>This page does not exist</li>
                    </ul>
                </div>
            </PageWithContent>
        </>
    );
}

Component.displayName = 'PageNotFoundPage';
